/* .wrapper-site-header_wrapper{
  display: flex;
  align-items: center;
  width: 100%;
  
}
.title-app{
  /* margin-left: 1%; */
  /* margin-right: 50%; 
 font-family: Roboto;
 width: 100%;
}
.nav_wrapper {
  display: flex;
  list-style: none; 
  margin-right: 0%; 
  gap: 6%;
  width: 100%;
  flex-basis: 20%;
} */



.mainpage-container {
   /* background-image: url('pictures/IMGP5181.jpg'); */
  background-color: aliceblue;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center; 
  /* background-color: blueviolet;  */
  height: 100vh;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  /* flex-direction: column; */
  align-items: stretch;
  justify-content: center;
  gap: 5%;

}

  .photo-page-main,.ui-page-main,.design-page-main,.illustration-page-main,.blog-page-main,.app-page-main{
  width: 40%;
  height: 40%;
  vertical-align: middle;
  text-align: center;

    }
  .button-main{
    width: 100%;
    height: 90%;
    font-size: calc(40px + 2vmin);
    background-image: url('pictures/IMGP5181-3.jpg');
    background-size: cover; /* Set the image to cover the entire element */
    background-position: center; /* Center the image within the element */
    cursor: pointer; /* Change the cursor to a pointer when hovering over the button */
  }
  .button-title-main{
    font-size: small;
  }




/* link:hover{
    text-decoration: underline;
} */
.link{
  text-decoration: none;  
  margin-right:auto;
  margin-left:auto;
  height: 100%;
  width: 100%;



}
